import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'jquery';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import './App.css';

import caGregorian from 'cldr-data/main/hu/ca-gregorian.json';
import numbers from 'cldr-data/main/hu/numbers.json';
import timeZoneNames from 'cldr-data/main/hu/timeZoneNames.json';

import versionService from './services/versionService';

import confirm from './utils/confirm';

import {
  L10n,
  setCulture,
  setCurrencyCode,
  loadCldr,
} from '@syncfusion/ej2-base';
import Public from './Public';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn:
    'https://a945b602c5844cd4b468eb99bea08d19@o404879.ingest.sentry.io/5952721',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

L10n.load({
  hu: {
    grid: {
      EmptyRecord: 'Nincs megjeleníthető adat.',
      GroupDropArea: 'Húzza ide a csoportosítani kívánt oszlopot',
      UnGroup: 'Csoportosítás eltávolítása',
      EmptyDataSourceError: 'Nincs megjeleníthető adat.',
      Item: 'db',
      Items: 'db',
      autoFitAll: 'Összes oszlop méretezése',
      autoFit: 'Aktuális oszlop méretezése',
      SortAscending: 'Rendezés növekvő sorrendbe',
      SortDescending: 'Rendezés csökkenő sorrendbe',
      Columnchooser: 'Oszlopok',
      Group: 'Csoportosítás',
      Ungroup: 'Csoportosítás eltávolítása',
      All: 'Minden',
    },
    pager: {
      pagerAllDropDown: 'db',
      All: 'Minden',
      currentPageInfo: '{0} / {1} Oldal',
      totalItemsInfo: '({0} találat)',
      firstPageTooltip: 'Első oldal',
      lastPageTooltip: 'Utolsó oldal',
      nextPageTooltip: 'Következő oldal',
      previousPageTooltip: 'Előző oldal',
      nextPagerTooltip: 'További oldalak',
      previousPagerTooltip: 'Előző oldalak',
      pagerDropDown: 'db találat / oldal',
    },
  },
});

loadCldr(caGregorian, numbers, timeZoneNames);
setCulture('hu');
setCurrencyCode('Ft');

var numeral = require('numeral');
numeral.register('locale', 'hu', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
});
numeral.locale('hu');

versionService.getVersion().then(({ data: version }) => {
  (window as any).version = version;
  console.log('VERSION', version);

  const [tenant] = window.location.hostname.split('.');

  ReactDOM.render(
    <BrowserRouter
      getUserConfirmation={(message, callback) =>
        confirm(message, () => {
          callback(true);
        })
      }
    >
      {tenant === 'eszaf' ? <Public /> : <App />}
    </BrowserRouter>,
    document.getElementById('root')
  );
  serviceWorker.unregister();
});
